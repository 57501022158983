// CUSTOM JavaScript
import './jquery-global.js';
import './getWitnesses.js';
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .not('[href="#carouselExampleControls"]')
  .click(function (event) {
    // On-page links
	if (
		location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
		location.hostname === this.hostname
	) {
      // Figure out element to scroll to
		let target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
		// Does a scroll target exist?
		if (target.length !== 0) {
        // Only prevent default if animation is actually gonna happen
			event.preventDefault();
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 1000, function () {
          // Callback after animation
          // Must change focus!
				let $target = $(target);
				$target.focus();
				if ($target.is(':focus')) { // Checking if the target was focused
					return false;
				}
				$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
				$target.focus(); // Set focus again
			});
		}
	}
});

function carouselNormalization() {
	const items = $('#carouselExampleControls .carousel-item'); // Grab all slides
	let heights = []; // Create empty array to store height values
	let tallest; // Create variable to make note of the tallest slide

	if (items.length > 0) {
		function normalizeHeights() {
			items.each(function () { // Add heights to array
				heights.push($(this).height());
			});
			tallest = Math.max.apply(null, heights); // Cache largest value
			items.each(function () {
				$(this).css('min-height', tallest + 'px');
			});
		}
		normalizeHeights();
		$(window).on('resize orientationchange', function () {
			tallest = 0;
			heights.length = 0; // Reset vars
			items.each(function () {
				$(this).css('min-height', '0'); // Reset min-height
			});
			normalizeHeights(); // Run it again
		});
	}
}

$('.carousel').carousel({
	interval: 5000
});
carouselNormalization();

$('.select[name=role]').change(function () {
	$(this.value === 'role' ? '' : $(this).css('color', 'rgb(101, 23, 89)'));
});
